import icon1 from "./1.svg";
import icon2 from "./2.svg";
import icon3 from "./3.svg";
import icon4 from "./4.svg";
import woman from "./g.gif";
import message from "./message.svg";
import logo from "./logo.svg";
import back from "./back.png";
import HeadShake from "react-reveal/HeadShake";
import "./style.css";
import Typewriter from "typewriter-effect";
import { Flip } from "react-reveal";
import Zoom from "react-reveal/Zoom";
import Rotate from "react-reveal/Rotate";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useEffect, useState } from "react";
import tg from "./tg.svg";
import wt from "./wt.svg";
export default function Main() {
  const [razdel, setRazdel] = useState([]);
  const [click, setClick] = useState(false);
  function getCards() {
    fetch("https://comeback-team.ru/turkeyApi/getCard.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRazdel(data);
      });
  }
  useEffect(() => {
    getCards();
  }, []);
  console.log(razdel);
  const Navigate = useNavigate();
  return (
    <div className="Main">
      <div className="oval" style={{ backgroundImage: `url(${back})` }}>
        <header>
          <img src={logo} alt="" className="logo" />
          <Zoom left delay={500}>
            <div
              className="refs"
              style={click ? { right: "-1vw" } : { right: "-30vw" }}
              id="links"
            >
              <a href="https://wa.me/905397746898">
                <img src={wt} alt="" />
              </a>
              <a href="https://t.me/+905397746898">
                <img src={tg} alt="" />
              </a>
            </div>
            <a
              className="send"
              onClick={() => {
                setClick(!click);
              }}
            >
              <img src={message} alt="" />
              <p>Написать</p>
            </a>
          </Zoom>
          <img src={woman} className="manager" alt="" />
        </header>

        {/*  <Typewriter
          options={{
            strings: ["База Алании 3000+", "Кэшбэк 5.000 €", "Взнос всего 10%"],
            autoStart: true,
            loop: true,
          }}
        /> */}
      </div>
      <div className="main_content">
        <div className="blocks">
          {razdel &&
            razdel.length > 0 &&
            razdel.map((em, index) => {
              return (
                <Zoom>
                  <div
                    className="block"
                    onClick={() => {
                      localStorage.setItem("city", em.nameR);
                      Navigate(routes.objects + `?key=${em.nameR}`);
                    }}
                  >
                    <svg
                      width="124"
                      height="123"
                      viewBox="0 0 124 123"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_1068_83)">
                        <path
                          d="M91.1319 72.7142H32.4138C30.5284 72.7142 29 74.2427 29 76.1281V77.4936C29 79.379 30.5284 80.9075 32.4138 80.9075H91.1319C93.0174 80.9075 94.5458 79.379 94.5458 77.4936V76.1281C94.5458 74.2427 93.0174 72.7142 91.1319 72.7142Z"
                          fill="white"
                        />
                        <path
                          d="M91.7037 43.7234L92.971 41.824C93.464 41.0903 93.652 40.1935 93.4953 39.3235C93.3385 38.4536 92.8493 37.6789 92.1311 37.1634L63.773 16.6476C63.1909 16.2266 62.4909 16 61.7725 16C61.0542 16 60.3541 16.2266 59.772 16.6476L31.4139 37.1634C30.6958 37.6789 30.2065 38.4536 30.0498 39.3235C29.893 40.1935 30.0811 41.0903 30.5741 41.824L31.8413 43.7234C32.0979 44.1033 32.4281 44.428 32.8122 44.6782C33.1963 44.9284 33.6267 45.0992 34.0779 45.1804C34.5291 45.2616 34.992 45.2516 35.4393 45.151C35.8866 45.0504 36.3092 44.8612 36.6821 44.5947L61.3724 26.7348C61.4888 26.6506 61.6289 26.6053 61.7725 26.6053C61.9162 26.6053 62.0562 26.6506 62.1726 26.7348L86.8629 44.5947C87.2358 44.8616 87.6583 45.0511 88.1057 45.1519C88.553 45.2527 89.016 45.2628 89.4673 45.1816C89.9187 45.1003 90.3491 44.9294 90.7332 44.679C91.1173 44.4286 91.4474 44.1036 91.7037 43.7234Z"
                          fill="white"
                        />
                        <path
                          d="M61.9556 39.4359C61.3345 39.4268 60.7247 39.602 60.2032 39.9395C59.6817 40.277 59.272 40.7615 59.0259 41.3318C58.7798 41.9021 58.7083 42.5326 58.8204 43.1435C58.9326 43.7544 59.2234 44.3184 59.6561 44.7641C60.0888 45.2097 60.6439 45.5171 61.2513 45.6473C61.8586 45.7775 62.4909 45.7246 63.0683 45.4955C63.6456 45.2663 64.142 44.8711 64.4947 44.3598C64.8475 43.8485 65.0407 43.2441 65.0499 42.6231C65.0561 42.2106 64.9809 41.801 64.8287 41.4176C64.6765 41.0342 64.4503 40.6845 64.163 40.3885C63.8756 40.0926 63.5328 39.8561 63.154 39.6926C62.7753 39.5292 62.3681 39.4419 61.9556 39.4359Z"
                          fill="white"
                        />
                        <path
                          d="M86.0655 45.7034L62.1686 28.4157C62.0521 28.3356 61.914 28.2927 61.7726 28.2927C61.6312 28.2927 61.4931 28.3356 61.3766 28.4157L37.4797 45.7034C37.1121 45.9672 36.7071 46.1743 36.278 46.3179C36.1449 46.3679 36.0303 46.4576 35.9497 46.5748C35.8691 46.692 35.8264 46.8311 35.8274 46.9733V70.6654C35.8274 70.8465 35.8993 71.0202 36.0274 71.1482C36.1554 71.2763 36.3291 71.3482 36.5102 71.3482H87.0351C87.2161 71.3482 87.3898 71.2763 87.5179 71.1482C87.6459 71.0202 87.7178 70.8465 87.7178 70.6654V46.9733C87.7188 46.8311 87.6761 46.692 87.5956 46.5748C87.515 46.4576 87.4004 46.3679 87.2672 46.3179C86.837 46.177 86.4316 45.9697 86.0655 45.7034ZM66.6626 55.3072L66.5343 64.1204C66.5209 65.0157 66.1737 65.8738 65.5606 66.5265L62.8446 69.4173C62.6736 69.5991 62.4666 69.7432 62.2368 69.8405C62.007 69.9378 61.7594 69.9861 61.5099 69.9824C61.2604 69.9786 61.0144 69.9229 60.7876 69.8188C60.5608 69.7147 60.3582 69.5645 60.1927 69.3777L56.9373 65.7044C56.8582 65.6155 56.8177 65.4988 56.8247 65.38C56.8316 65.2612 56.8853 65.15 56.9741 65.0708L58.2905 63.9019C58.378 63.8243 58.4316 63.7157 58.4401 63.5991C58.4485 63.4825 58.411 63.3672 58.3356 63.2779L56.9454 61.6228C56.8761 61.5415 56.838 61.4382 56.838 61.3313C56.838 61.2244 56.8761 61.1211 56.9454 61.0397L58.3356 59.4421C58.4108 59.3562 58.4503 59.2449 58.4459 59.1309C58.4416 59.0169 58.3938 58.9088 58.3123 58.8289L57.2991 57.828C57.1265 57.658 56.99 57.4548 56.898 57.2307C56.806 57.0066 56.7604 56.7662 56.7638 56.5239L56.7843 55.1584C54.7859 54.0315 53.2199 52.271 52.3336 50.155C51.4473 48.0389 51.2912 45.6879 51.8899 43.4732C52.4887 41.2586 53.8082 39.3065 55.64 37.9253C57.4719 36.5441 59.7116 35.8126 62.0056 35.8462C64.2995 35.8798 66.5169 36.6767 68.3074 38.111C70.098 39.5453 71.3597 41.5352 71.8933 43.7665C72.4269 45.9977 72.2019 48.3432 71.254 50.4323C70.306 52.5215 68.6891 54.2353 66.6585 55.3031L66.6626 55.3072Z"
                          fill="white"
                        />
                        <path
                          d="M61.7587 54.9116C61.5776 54.9116 61.4039 54.9836 61.2759 55.1116C61.1479 55.2396 61.0759 55.4133 61.0759 55.5944V60.0829C61.0759 60.264 61.1479 60.4377 61.2759 60.5657C61.4039 60.6937 61.5776 60.7657 61.7587 60.7657C61.9398 60.7657 62.1134 60.6937 62.2415 60.5657C62.3695 60.4377 62.4415 60.264 62.4415 60.0829V55.5944C62.4415 55.4133 62.3695 55.2396 62.2415 55.1116C62.1134 54.9836 61.9398 54.9116 61.7587 54.9116Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_1068_83"
                          x="0"
                          y="0"
                          width="123.546"
                          height="122.907"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="13" />
                          <feGaussianBlur stdDeviation="14.5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_1068_83"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_1068_83"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>

                    <div>
                      <p>недвижимость</p>
                      <h4>{em.nameR}</h4>
                    </div>
                  </div>
                </Zoom>
              );
            })}
        </div>
      </div>
    </div>
  );
}
