import "./style.css";
import back from "./back.svg";
import tel from "./tel.svg";
import { useRef, useState } from "react";
import tg from "./tg.svg";
import wt from "./wt.svg";
import tur from "./tur.svg";
import rus from "./rus.svg";
export default function Header() {
  const copyInputHolder = useRef(null);
  const copyInputHolder2 = useRef(null);
  const copiedBox = useRef(null);
  const [nomer, openNomer] = useState(false);
  const copyText = (num) => {
    if (num == 1) {
      copyInputHolder.current.select();
    } else {
      copyInputHolder2.current.select();
    }

    document.execCommand("copy");
    openNomer(false);
    copiedBox.current.style.top = "3vh";
    setTimeout(() => {
      copiedBox.current.style.top = "-15vh";
    }, 2000);
  };
  return (
    <div className="headerMain">
      <div
        className="back"
        onClick={() => {
          window.history.back();
        }}
      >
        <img src={back} alt="" />
        <p>Назад</p>
      </div>
      <input
        ref={copyInputHolder}
        value={"+90 536 724 39 71"}
        className="dont_have_mavieID_inviter_name_phone"
        readOnly
        style={{ display: "none" }}
      ></input>
      <div className="tel">
        <p>
          <a href="https://wa.me/905397746898" target="_blank">
            <img src={wt} alt="" />
          </a>
          <a href="https://t.me/+905397746898" target="_blank">
            <img src={tg} alt="" />
          </a>
        </p>
        <div className="pozvon" onClick={() => openNomer(!nomer)}>
          Позвонить
        </div>
        {nomer && (
          <div className="nomers">
            <div onClick={() => copyText(1)}>
              <img src={tur} alt="" />
              <input
                ref={copyInputHolder}
                value={"+90 242 900 13 23"}
                readOnly
              ></input>
            </div>
            <div onClick={() => copyText(2)}>
              <img src={rus} alt="" />{" "}
              <input
                ref={copyInputHolder2}
                value={"+7 911 670 00 03"}
                readOnly
              ></input>
            </div>
          </div>
        )}
      </div>
      <div ref={copiedBox} className="copied_phone">
        Номер телефона <br /> скопирован
      </div>
    </div>
  );
}
